exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-ats-jsx": () => import("./../../../src/pages/ats.jsx" /* webpackChunkName: "component---src-pages-ats-jsx" */),
  "component---src-pages-carbon-neutrality-jsx": () => import("./../../../src/pages/carbon-neutrality.jsx" /* webpackChunkName: "component---src-pages-carbon-neutrality-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-digital-campus-jsx": () => import("./../../../src/pages/digital-campus.jsx" /* webpackChunkName: "component---src-pages-digital-campus-jsx" */),
  "component---src-pages-executive-search-jsx": () => import("./../../../src/pages/executive-search.jsx" /* webpackChunkName: "component---src-pages-executive-search-jsx" */),
  "component---src-pages-headhunting-jsx": () => import("./../../../src/pages/headhunting.jsx" /* webpackChunkName: "component---src-pages-headhunting-jsx" */),
  "component---src-pages-impact-jsx": () => import("./../../../src/pages/impact.jsx" /* webpackChunkName: "component---src-pages-impact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-pre-screening-jsx": () => import("./../../../src/pages/pre-screening.jsx" /* webpackChunkName: "component---src-pages-pre-screening-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-terms-and-conditions-jsx": () => import("./../../../src/pages/terms-and-conditions.jsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-jsx" */),
  "component---src-pages-usability-jsx": () => import("./../../../src/pages/usability.jsx" /* webpackChunkName: "component---src-pages-usability-jsx" */)
}

